module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"database":true,"firestore":true,"storage":false,"messaging":false,"functions":true,"performance":false},"credentials":{"apiKey":"AIzaSyB-CfQmRMCNkx-aXMILhu-pq9Gl2iLP1bY","authDomain":"lead-dashboard-f02fb.firebaseapp.com","databaseURL":"https://lead-dashboard-f02fb.firebaseio.com","projectId":"lead-dashboard-f02fb","storageBucket":"lead-dashboard-f02fb.appspot.com","messagingSenderId":"19031614271","appId":"1:19031614271:web:734cf59d75b495af14e98a","measurementId":"G-VCE4XZ18SH"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"272bd02c-6c25-454e-b1f5-e5d7b694cdf2","enableDuringDevelop":true,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Guerra Finanças Home Equity","short_name":"Home Equity","lang":"pt","start_url":"/","background_color":"#00205B","theme_color":"#00205B","display":"standalone","icon":"src/images/Simbolo-Positivo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
