/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './node_modules/bootstrap/dist/css/bootstrap.css'

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Esta aplicação foi atualizada. ` +
        `Recarregar agora para atualizar com as ultimas novidades?`
    )
    if (answer === true) {
      window.location.reload()
    }
  }